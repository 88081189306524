<template>
  <v-fade-transition>
    <v-row
      v-show="imageReady"
      no-gutters
      justify="center"
      style="height:80vh"
      align="center"
    >
      <v-card class="pa-8" max-width="900" style="margin-top:5vh" flat outlined>
        <v-row justify="center" no-gutters>
          <v-col class="flex-grow-0 flex-shrink-0">
            <v-card flat class="pa-3" min-width="370" max-width="370">
              <v-row no-gutters justify="center">
                <img
                  id="loginImage"
                  style="width: 80%; max-height: 180px; object-fit: contain;"
                  src="https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FNovoRPM%20Horiz.svg?alt=media&token=83350ffc-36cb-4603-9b59-d4a0dd968043"
                />
              </v-row>
              <v-row
                justify="center"
                class="font-weight-light mt-7 mb-7"
                style="font-size: 24px"
                no-gutters
              >
                <span>Create your account</span>
              </v-row>
              <v-form ref="registrationForm">
                <v-text-field
                  outlined
                  label="First Name"
                  name="First Name"
                  type="firstname"
                  required
                  v-model="firstName"
                  :rules="firstNameRules"
                />
                <v-text-field
                  outlined
                  label="Last Name"
                  name="Last Name"
                  type="lastname"
                  required
                  v-model="lastName"
                  :rules="lastNameRules"
                />
                <v-text-field
                  :readonly="lockEmailInput"
                  outlined
                  label="Email"
                  name="email"
                  type="text"
                  required
                  v-model="email"
                  :rules="emailRules"
                />

                <v-text-field
                  outlined
                  id="password"
                  label="Create Password"
                  name="password"
                  persistent-hint
                  hint="At least 6 characters"
                  :append-icon="isPassVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (isPassVisible = !isPassVisible)"
                  :type="isPassVisible ? 'text' : 'password'"
                  required
                  v-model="password"
                  :rules="[passwordRules.required, passwordRules.min]"
                />
              </v-form>

              <v-row justify="space-between" no-gutters>
                <v-btn @click="switchToSignIn()" text color="primary"
                  >Sign In Instead</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn @click="submitRegistration()" depressed color="primary">
                  <v-fade-transition hide-on-leave>
                    <span v-show="!registerLoading">
                      Create Account
                    </span>
                  </v-fade-transition>
                  <v-fade-transition hide-on-leave>
                    <v-progress-circular
                      style="width:124px;"
                      v-show="registerLoading"
                      size="20"
                      indeterminate
                    />
                  </v-fade-transition>
                </v-btn>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            v-if="!isMobile"
            class="flex-grow-0 flex-shrink-0 ml-8"
            style="border-left:1px solid #eeeeee"
          >
            <v-card flat width="400">
              <v-card-text>
                <v-card flat v-for="(item, i) in monitorFeatureList" :key="i">
                  <v-list-item class="pa-0 ma-0">
                    <v-list-item-avatar class="ma-0 pa-0 mr-2">
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-text
                    style="opacity:0.8; margin-top: -12px; padding-left: 49px; padding-top:2px;"
                  >
                    {{ item.text }}
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-fade-transition>
</template>

<script>
import HelperMixin from '@/core/mixins/HelperMixin'
import bus from '@/core/helpers/bus'
import { mapActions, mapState } from 'vuex'
import consts from '@/consts'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      imageReady: false,
      tokenHere: null,
      userNeedsUpdate: false,
      registerLoading: false,
      signUpType: 'register',
      inviteEmail: null,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      firstNameRules: [v => !!v || 'First name is required'],
      lastNameRules: [v => !!v || 'Last name is required'],
      emailRules: [v => !!v || 'E-mail is required'],
      isPassVisible: true,
      passwordRules: {
        required: value => !!value || 'Password is required.',
        min: v => (!!v && v.length >= 6) || 'At least 6 characters',
      },
      monitorFeatureList: [
        {
          title: 'Secure Protected Storage',
          text:
            'Store your health information in a secure HIPAA Compliant cloud platform.',
          icon: 'mdi-lock',
        },
        {
          title: 'Access Anywhere',
          text: 'Access your records from any internet enabled device.',
          icon: 'mdi-earth',
        },
        {
          title: 'Share With Anyone',
          text:
            'Securely share your chart with provider, care team members, or family.',
          icon: 'mdi-account-multiple-plus',
        },
        {
          title: 'Real Time Health Data',
          text:
            'Monitor health data like vitals and ADLs in real time as measurements are taken.',
          icon: 'mdi-timer',
        },
        {
          title: 'Minimal Setup Required',
          text:
            'Connected devices come preconfigured and ready to use. No mobile app, bluetooth connection, or WiFi needed.',
          icon: 'mdi-thumb-up',
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['appTheme', 'user']),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    lockEmailInput() {
      if (this.tokenHere) {
        return true
      }
      return false
    },
    version() {
      return consts.version
    },
  },
  watch: {
    user(val) {
      console.log('user has been changed')
      // user is back. if there is no token to apply, just go to the correct page. if there is a token, apply the token first
      // this will be called again when the token is applied.
      if (val) {
        if (this.userNeedsUpdate) {
          this.updateUserName({
            firstName: this.firstName,
            lastName: this.lastName,
          })
          this.userNeedsUpdate = false
        } else if (this.tokenHere) {
          console.log('about to apply the token from registration')
          this.applyRegistrationToken({ loginToken: this.tokenHere })
          this.tokenHere = null
        } else {
          if (val.partnerID) {
            // you are part of a partner, go to the partner dashboard
            // look for a token here? dont apply if you are already part of a partner?
            this.$router.push({ name: 'Taskboard' })
          } else {
            // you are not part of the partner. go to the user dashboard
            // maybe look for a token here?
            this.$router.push({ name: 'PatientFacingView' })
          }
        }
      } else {
        console.log('user is empty')
      }
    },
  },
  methods: {
    ...mapActions('auth', [
      'signUp',
      'applyRegistrationToken',
      'updateUserName',
    ]),
    ...mapActions('provider', ['getProviderAuth']),
    switchToSignIn() {
      this.$router.push({ name: 'Login', query: this.$route.query })
    },
    parseURLParameters() {
      const search = this.$route.query
      if (search.loginToken) {
        this.tokenHere = search.loginToken
        console.log('found login token')
        let info = this.parseSignupJWT(search.loginToken)
        console.log('token identified, parsing')
        console.log(info)
        if (search.email) {
          console.log(search.email)
          console.log(decodeURIComponent(search.email))
          this.inviteEmail = decodeURIComponent(search.email)
          this.email = decodeURIComponent(search.email)
        }
        if (info) {
          this.signUpType = info.ocType
        } else {
          this.toast('error', 'Error loading token')
        }
      } else {
        this.signUpType = 'New Registration'
      }
    },
    togglePasswordVisibility() {
      this.isPassVisible = !this.isPassVisible
    },
    submitRegistration() {
      if (this.$refs.registrationForm.validate()) {
        this.registerLoading = true
        let signUpPayload = {
          email: this.email,
          password: this.password,
          firstName: this.firstName,
          lastName: this.lastName,
        }
        if (this.email && this.password && this.firstName && this.lastName) {
          this.userNeedsUpdate = true
          this.signUp(signUpPayload)
            .then(() => {
              bus.$emit('toast', {
                type: 'success',
                text: 'Welcome to Novo RPM',
              })
            })
            .catch(error => {
              console.log(error)
              switch (error.code) {
                case 'auth/email-already-in-use':
                  bus.$emit('toast', { type: 'error', text: 'Email in use' })
                  break
                case 'auth/invalid-email':
                  bus.$emit('toast', { type: 'error', text: 'Invalid Email' })
                  break
                case 'auth/weak-password':
                  bus.$emit('toast', { type: 'error', text: 'Weak password' })
                  break
                default:
                  bus.$emit('toast', { type: 'error', text: error.code })
              }
              this.registerLoading = false
            })
        } else {
          this.registerLoading = false
        }
      }
    },
  },
  mounted() {
    let img = document.getElementById('loginImage')
    let vm = this
    img.onload = function() {
      vm.imageReady = true
    }

    img.onerror = function() {
      vm.imageReady = true
    }
    this.registerLoading = false
    this.parseURLParameters()
  },
}
</script>
